import React, { useState, useRef, useEffect , useCallback } from 'react';
import AddClientLocation from "./AddClientLocation";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from 'axios';
import { API_URL } from "../constants";
import SetDefaultEmailFrequency from './SetDefaultEmailFrequency';
import { FaEdit } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { fr } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import moment from 'moment';
import 'moment/locale/fr';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import differenceInDays from 'date-fns/differenceInDays';
import logoAiga from "./logoAiga.png";
import { addDays } from "date-fns";

registerLocale('fr', fr);

export default function DetailsCompteur(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  const location = useLocation();
 const [motor, setMotor] = useState(location.state?.boitier || {});
  const [isEditing, setIsEditing] = useState(false);
  const [newMotorName, setNewMotorName] = useState(motor.name);

  const [startDate, setStartDate] = useState(addDays(new Date(),0));
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState('day');
  const [availableGranularities, setAvailableGranularities] = useState(['day']);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [debitSeuil, setDebitSeuil] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const chartContainerRef = useRef();
  const secondChartContainerRef = useRef();
  const [impulseVolume, setImpulseVolume] = useState('');
  const [unit, setUnit] = useState('litre');
  const [unitConsumption, setUnitConsumption] = useState('litres');
  const [etalonnageName, setEtalonnageName] = useState('');
  const [etalonnageHistory, setEtalonnageHistory] = useState([]);
  const [creatingEtalonnage, setCreatingEtalonnage] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEtalonnage, setSelectedEtalonnage] = useState(null);
  const [boitier, setBoitier] = useState(null);
  const [consumptions, setConsumptions] = useState({});


 // Fonction pour gérer la demande de suppression d'un étalonnage
 const handleDeleteRequest = (etalonnage) => {
  setSelectedEtalonnage(etalonnage); // Enregistre l'étalonnage sélectionné
  setShowModal(true); // Affiche la modale
};

// Fonction pour supprimer un étalonnage après confirmation
const handleDeleteConfirm = async () => {
  if (selectedEtalonnage) {
    await handleDeleteEtalonnage(selectedEtalonnage._id); // Appelle la fonction de suppression
    setShowModal(false); // Ferme la modale après la suppression
  }
};

const handleUnitChange = (e) => {
  setUnit(e.target.value); // Met à jour l'unité en fonction de la sélection
};

const handleUnitConsumptionChange = (event) => {
  setUnitConsumption(event.target.value);
};

// Fonction pour capturer le changement du nom d'étalonnage
const handleEtalonnageNameChange = (e) => {
  setEtalonnageName(e.target.value);
};

// Fonction pour capturer le changement du volume par impulsion
const handleVolumeChange = (e) => {
  setImpulseVolume(e.target.value);
};

useEffect(() => {
  if (confirmationMessage) {
    const timer = setTimeout(() => {
      setConfirmationMessage('');
    }, 3000); // 3000 millisecondes = 3 secondes

    // Nettoyage de l'effet pour éviter des mises à jour d'état après le démontage
    return () => clearTimeout(timer);
  }
}, [confirmationMessage]);

const handleCreateEtalonnage = async () => {
  if (!impulseVolume || isNaN(parseFloat(impulseVolume))) {
    console.error('Volume par impulsion invalide.');
    return;
  }

    // Requête POST pour créer un nouvel étalonnage
    const response = await axios.post(`${API_URL}/boitier/etalonnage`, {
      nom: etalonnageName,  // Nom attribué par l'utilisateur
      valeur: parseFloat(impulseVolume)  // Volume par impulsion
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    const id_etalonnage = response.data.data._id;

    // Associer l'étalonnage au boîtier
    await axios.put(`${API_URL}/boitier/setetalonnage`, {
      id_boitier: motor.id,
      id_etalonnage: id_etalonnage
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    // Spécifier la date de début de l'étalonnage
    const dateDebut = moment().format('YYYY-MM-DD');  // Date actuelle
    await axios.put(`${API_URL}/boitier/debutetalonnage`, {
      id_etalonnage: id_etalonnage,
      dateDebut: dateDebut
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Étalonnage créé et associé avec succès.');
    setConfirmationMessage('Étalonnage créé avec succès !');
    setCreatingEtalonnage(false);

    try {
      // Requête pour récupérer la liste des boîtiers de l'utilisateur
      const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      });
  
      const boitier = response.data.data.find(b => b._id === motor.id);
      if (!boitier) {
        console.error('Boîtier non trouvé.');
        return;
      }
  
      const etalonnageIds = boitier.id_etalonnage;
      const etalonnage = etalonnageIds[etalonnageIds.length - 2];
      handleEndEtalonnage(etalonnage);

    } catch (error) {
      console.error("Erreur lors de la récupération de l'étalonnage :", error);
    }
};


const handleEndEtalonnage = async (id_etalonnage) => {
  try {
    const dateFin = moment().format('YYYY-MM-DD');  // Date actuelle

    await axios.put(`${API_URL}/boitier/finetalonnage`, {
      id_etalonnage: id_etalonnage,
      dateFin: dateFin
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Date de fin de l\'étalonnage mise à jour avec succès.');
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la date de fin:', error);
  }
};

const fetchEtalonnageHistory = async () => { 
  try {
    // Requête pour récupérer la liste des boîtiers de l'utilisateur
    const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    const boitier = response.data.data.find(b => b._id === motor.id);
    if (!boitier) {
      console.error('Boîtier non trouvé.');
      return;
    }

    const etalonnageIds = boitier.id_etalonnage;  // Tableau d'ID des étalonnages associés au boîtier

    // Requête pour récupérer tous les étalonnages
    const etalonnageResponse = await axios.get(`${API_URL}/boitier/listetalonnage`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    // Filtrer les étalonnages basés sur les ID récupérés
    const etalonnages = etalonnageResponse.data.data.filter(etalonnage => 
      etalonnageIds.includes(etalonnage._id)
    );

    setEtalonnageHistory(etalonnages);  // Stocker l'historique dans un état local pour l'afficher

    console.log('Historique des étalonnages récupéré avec succès.');
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'historique des étalonnages:', error);
  }
};

// Fonction pour supprimer un étalonnage
const handleDeleteEtalonnage = async (id_etalonnage) => {
  try {
    const response = await axios.delete(`${API_URL}/boitier/deletalonnage`, {
      data: { id: id_etalonnage },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    if (response.status === 204) {
      // Mise à jour de l'historique après suppression
      setEtalonnageHistory(prevHistory => 
        prevHistory.filter(etalonnage => etalonnage._id !== id_etalonnage)
      );
      console.log('Étalonnage supprimé avec succès.');
    } else {
      console.error('Erreur lors de la suppression : Réponse inattendue.');
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'étalonnage:', error);
  }
};

useEffect(() => {
  const fetchBoitierData = async () => {
    try {
      const response = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      });

      // Trouver le boîtier correspondant
      const boitierData = response.data.data.find(b => b._id === motor.id);
      
      // Mettre à jour l'état du boîtier
      if (boitierData) {
        setBoitier(boitierData);
      } else {
        console.error("Boîtier non trouvé");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du boîtier :", error);
    }
  };

  fetchBoitierData();
}, [user.userId, user.token, motor.id]);


const fetchImpulseVolume = useCallback(async () => {
  try {
    // Requête pour récupérer la liste des boîtiers de l'utilisateur
    const responseBoitiers = await axios.get(`${API_URL}/boitier/listboitier?id=${user.userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      },
    });

    // Rechercher le boîtier spécifique avec son _id
    const boitier = responseBoitiers.data.data.find(b => b._id === motor.id);
    if (boitier && boitier.id_etalonnage) {
      // Récupérer la liste des étalonnages
      const responseEtalonnages = await axios.get(`${API_URL}/boitier/listetalonnage`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
      });

      // Filtrer les étalonnages qui correspondent aux IDs dans boitier.id_etalonnage
      const etalonnages = responseEtalonnages.data.data.filter(etalonnage => 
        boitier.id_etalonnage.includes(etalonnage._id)
      );

      // Vérifier si des étalonnages sont disponibles pour ce boîtier
      if (etalonnages.length > 0) {
        const latestEtalonnage = etalonnages[etalonnages.length - 1];  // Récupérer le dernier étalonnage
        console.log(latestEtalonnage);

        if (latestEtalonnage && latestEtalonnage.valeur) {
          setImpulseVolume(latestEtalonnage.valeur);
          setDebitSeuil(Math.round((boitier.depassement * (1/latestEtalonnage.valeur)).toFixed(0)));
        } else {
          console.error('Étalonnage introuvable ou sans valeur.');
        }
      } else {
        console.error('Aucun étalonnage trouvé pour ce boîtier.');
      }
    } else {
      console.error('Boîtier ou id_etalonnage introuvable.');
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'étalonnage :", error);
  }
}, [motor.id, user.userId, user.token]);


useEffect(() => {
  fetchImpulseVolume();  // Appel de la fonction dans le useEffect
}, [fetchImpulseVolume]);
  
  
  // Utilisation dans un useEffect pour récupérer les données lors de l'ouverture de la page
  useEffect(() => { 
    fetchImpulseVolume();
  }, [motor.id, user.id, user.token, fetchImpulseVolume]); 
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Mettre à jour si la largeur d'écran est inférieure à 768px (taille mobile)
    };

    // Ajouter un écouteur d'événement pour le redimensionnement
    window.addEventListener('resize', handleResize);

    // Appeler handleResize au montage du composant pour obtenir la taille actuelle
    handleResize();

    // Nettoyer l'écouteur d'événement lorsque le composant est démonté
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const daysDiff = differenceInDays(endDate, startDate) + 1;

    let granularities = [];

    if (daysDiff < 2) {
      granularities.push('day');
    }

    if (daysDiff >= 7) {
      granularities.push('week');
    }

    const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    if (monthsDiff >= 1) {
      granularities.push('month');
    }

    if (daysDiff >= 365) {
      granularities.push('year');
    }

    setAvailableGranularities(granularities);

    if (!granularities.includes(granularity)) {
      setGranularity(granularities[0]);
    }
  }, [startDate, endDate, granularity]);

  useEffect(() => {
    // Generate hourly data for the second chart
    const generateHourlyData = () => {
      const hourlyGeneratedData = [];
      for (let hour = 0; hour < 24; hour++) {
        hourlyGeneratedData.push({
          name: `${String(hour).padStart(2, '0')}:00`,
          Debit: Math.floor(Math.random() * 50) + 10
        });
      }
    };
    generateHourlyData();
  }, []);

  // Fonction pour gérer l'envoi du seuil de dépassement
const handleSubmitSeuil = async () => {
  try {


    if (isNaN(impulseVolume) || impulseVolume <= 0) {
      console.error("Volume par impulsion invalide:", impulseVolume);
      return;
    }

    // Convertir le seuil en litres en nombre d'impulsions
    const seuil = 1/impulseVolume
    const seuilImpulsions = Math.round(debitSeuil / seuil);

    // Envoyer la requête PUT avec l'ID du boîtier et le seuil d'impulsions
    const response = await axios.put(`${API_URL}/boitier/depassement`, {
      id_boitier: motor.id,  // Assurez-vous que l'ID du boîtier est correct
      depassement: seuilImpulsions
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      }
    });

    console.log('Seuil de dépassement mis à jour avec succès', response.data);

  } catch (error) {
    console.error('Erreur lors de la mise à jour du seuil de dépassement', error);
  }
};

const calculateTotalConsumptionForDay = useCallback(async (dateDepassement) => {
  try {
    const startDate = moment(dateDepassement).subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    const endDate = moment(dateDepassement).add(1, 'days').startOf('day').format('YYYY-MM-DD');

    const response = await axios.get(`${API_URL}/?d1=${startDate}&d2=${endDate}&id=${motor.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.token,
      },
    });

    const trames = response.data.data;
    let totalConsumption = 0;

    trames.forEach((trame) => {
      const volumeInLitres = trame.impulsion * (1 / impulseVolume);
      totalConsumption += volumeInLitres;
    });

    const convertedConsumption = unitConsumption === 'm3' ? totalConsumption / 1000 : totalConsumption;
    return convertedConsumption.toFixed(0);
  } catch (error) {
    console.error('Erreur lors de la récupération des trames :', error);
    return 0;
  }
}, [motor.id, user.token, impulseVolume, unitConsumption]); // Ajouter les dépendances appropriées

// Utiliser useEffect pour calculer la consommation dès que les dates de dépassement changent
useEffect(() => {
  const fetchConsumptions = async () => {
    const consumptionData = {};
    for (const date of boitier.dateDepassement || []) {
      const consumption = await calculateTotalConsumptionForDay(date);
      consumptionData[date] = consumption;
    }
    setConsumptions(consumptionData); // Mettre à jour l'état avec les consommations calculées
  };

  if (boitier && boitier.dateDepassement) {
    fetchConsumptions();
  }
}, [boitier, calculateTotalConsumptionForDay]); // Ajouter calculateTotalConsumptionForDay ici également


const fetchData = useCallback(async (debut, fin) => {
  try {
    moment.locale('fr-FR');
    let newStartDate = moment(debut).subtract(1, 'day').format('YYYY-MM-DD');
    const newEndDate = moment(fin).add(1, 'day').format('YYYY-MM-DD');

    const fetchTrames = async (startDate, endDate) => {
      const response = await axios.get(`${API_URL}/?d1=${startDate}&d2=${endDate}&id=${motor.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        },
      });
      return response.data.data;
    };

    let responseData = await fetchTrames(newStartDate, newEndDate);

    if (responseData.length !== 0) {
      let lastAvailableTrame = null;
      let previousDate = moment(debut).subtract(1, 'day');

      while (!lastAvailableTrame && previousDate.isAfter(moment(debut).subtract(1, 'year'))) {
        const previousStartDate = previousDate.subtract(1, 'day').format('YYYY-MM-DD');
        const previousEndDate = previousDate.add(1, 'day').format('YYYY-MM-DD');

        const currentData = await fetchTrames(previousStartDate, previousEndDate);

        if (currentData.length > 0) {
          lastAvailableTrame = currentData[currentData.length - 1];
          responseData = [...currentData, ...responseData];
        }
        previousDate.subtract(1, 'day');
      }
    }

    responseData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    if (!impulseVolume || isNaN(impulseVolume)) {
      console.error("Volume par impulsion invalide:", impulseVolume);
      return;
    }

    // Calculer la moyenne des 5 premières impulsions
    const initialImpulsions = responseData.slice(0, 5).map(item => item.impulsion);
    const averageImpulse = initialImpulsions.length > 0 ? initialImpulsions.reduce((acc, val) => acc + val, 0) / initialImpulsions.length : 0;

    const threshold = averageImpulse * 1.2;  // 20 % de la moyenne

    const validData = responseData.filter((item, index) => {
      // Ignorer les trames si elles dépassent 20 % de la moyenne
      return item.impulsion <= threshold || index < 5;  // Toujours garder les 5 premières impulsions
    });

    // Assurer que validData est utilisé pour le reste des traitements
    validData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    const months = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    const formatNumber = (number) => {
      return number.toFixed(2);
    };

    const convertVolume = (volume) => {
      return unit === 'm3' ? volume / 1000 : volume;
    };

    const convertConsumptionVolume = (volume) => {
      return unitConsumption === 'm3' ? volume / 1000 : volume;
    };

    const formatTime = timestamp => {
      if (granularity === 'day') {
        return moment(timestamp).format('HH:mm');
      } else if (granularity === 'month') {
        return `Semaine ${moment(timestamp).isoWeek()}`;
      } else if (granularity === 'year') {
        const monthIndex = moment(timestamp).month();
        return `${months[monthIndex]}`;
      } else {
        return moment(timestamp).format('DD/MM/YYYY');
      }
    };

    const aggregateData = (data, granularity) => {
      const groupedData = data.reduce((acc, item, index, array) => {
        let key;
        if (granularity === 'week') {
          key = moment(item.timestamp).startOf('day').format('DD/MM/YYYY');
        } else if (granularity === 'month') {
          key = `Semaine ${moment(item.timestamp).isoWeek()}`;
        } else if (granularity === 'year') {
          const monthIndex = moment(item.timestamp).month();
          key = `${months[monthIndex]}`;
        } else {
          key = formatTime(item.timestamp);
        }

        if (!acc[key]) {
          acc[key] = { name: key, Consommation: 0, Debit: 0, count: 0 };
        }

        const volume = item.impulsion * (1 / impulseVolume);
        acc[key].Consommation += convertConsumptionVolume(volume);

        let hoursDifference = 1 / 6;  // Par défaut, 10 minutes
        if (index > 0) {
          const previousTimestamp = new Date(array[index - 1].timestamp);
          const currentTimestamp = new Date(item.timestamp);
          const timeDifferenceInMs = currentTimestamp - previousTimestamp;
          hoursDifference = timeDifferenceInMs / (1000 * 60 * 60);
        }

        acc[key].Debit += convertVolume(volume) / hoursDifference;
        acc[key].count += 1;

        return acc;
      }, {});

      return Object.values(groupedData).map(item => ({
        ...item,
        Debit: item.count > 0 ? parseFloat(formatNumber(item.Debit / item.count)) : 0,
        Consommation: parseFloat(formatNumber(item.Consommation))
      }));
    };

    if (granularity === 'day') {
      let accumulatedConsumption = 0;
      let lastTimestampFromPreviousDay = validData.length > 0 ? validData[0].timestamp : null;

      const dataConsumptionForCharts = validData.map((item, index, array) => {
        const isCurrentDay = moment(item.timestamp).isSame(debut, 'day');

        if (!isCurrentDay) {
          lastTimestampFromPreviousDay = item.timestamp;
          return null;
        }

        const volumeInLitres = item.impulsion * (1 / impulseVolume);
        const volumeConsumption = unitConsumption === 'm3' ? volumeInLitres / 1000 : volumeInLitres;

        accumulatedConsumption += volumeConsumption;

        return {
          name: moment(item.timestamp).format('HH:mm'),
          Consommation: parseFloat(formatNumber(accumulatedConsumption)),
        };
      }).filter(item => item !== null);

      const dataFlowForCharts = validData.map((item, index, array) => {
        const isCurrentDay = moment(item.timestamp).isSame(debut, 'day');
      
        if (!isCurrentDay) {
          return null;
        }
      
        const volumeInLitres = item.impulsion * (1 / impulseVolume);
        const volumeFlow = unit === 'm3' ? volumeInLitres / 1000 : volumeInLitres;
      
        let hoursDifference = 1 / 6;
        if (index > 0 || lastTimestampFromPreviousDay) {
          const previousTimestamp = index === 0 ? new Date(lastTimestampFromPreviousDay) : new Date(array[index - 1].timestamp);
          const currentTimestamp = new Date(item.timestamp);
          const timeDifferenceInMs = currentTimestamp - previousTimestamp;

          hoursDifference = timeDifferenceInMs > 0 ? timeDifferenceInMs / (1000 * 60 * 60) : 0.17;
        }
      
        return {
          name: moment(item.timestamp).format('HH:mm'),
          Debit: parseFloat(formatNumber(volumeFlow / hoursDifference)),
        };
      }).filter(item => item !== null);

      setData2(dataConsumptionForCharts);
      setData3(dataFlowForCharts);
    } else {
      const aggregatedData = aggregateData(validData, granularity);
      setData2(aggregatedData);
      setData3(aggregatedData);
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
  }
}, [motor.id, user.token, granularity, impulseVolume, unit, unitConsumption]); // Dépendances de useCallback

  // Fonction appelée lors du clic sur le bouton Rechercher
  const handleSearchClick = useCallback(() => {
    const debut = moment(startDate).format('YYYY-MM-DD');
    const fin = moment(endDate).format('YYYY-MM-DD');
    fetchData(debut, fin);
  }, [fetchData, startDate, endDate]);  // Dépendances de useCallback
  
  // useEffect pour récupérer les données lors de l'ouverture de la page
  useEffect(() => {
    handleSearchClick();
  }, [handleSearchClick]);
  
  const getChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Consommation du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Consommation de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Consommation du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Consommation de l'année ${year} - ${motor.name}`;
        default:
            return `Consommation - ${motor.name}`;
    }
  };

  const getChartTitlePDF = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Rapport du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Rapport de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Rapport du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Rapport de l'année ${year} - ${motor.name}`;
        default:
            return `Rapport - ${motor.name}`;
    }
  };

  const getChartTitleExcel = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Tableur du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Tableur de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Tableur du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Tableur de l'année ${year} - ${motor.name}`;
        default:
            return `Tableur - ${motor.name}`;
    }
  };

  const getSecondChartTitle = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const startDateFormatted = startDate.toLocaleDateString('fr-FR', options);
    const endDateFormatted = endDate.toLocaleDateString('fr-FR', options);
    const year = new Date().getFullYear();

    switch (granularity) {
        case 'day':
            return `Débit du jour (${startDateFormatted}) - ${motor.name}`;
        case 'week':
            return `Débit de la semaine (${startDateFormatted} au ${endDateFormatted}) - ${motor.name}`;
        case 'month':
            return `Débit du mois de ${startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })} - ${motor.name}`;
        case 'year':
            return `Débit de l'année ${year} - ${motor.name}`;
        default:
            return `Débit - ${motor.name}`;
    }
};


const exportPDF = async () => {
  const chartElement1 = chartContainerRef.current; // Premier graphique (consommation)
  const chartElement2 = secondChartContainerRef.current; // Second graphique (débit)

  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageWidth = pdf.internal.pageSize.width;
  let yPosition = 50; // Variable pour suivre la position verticale dynamique

  // PAGE 1 - Header et graphiques
  // Ajouter le logo
  const logoUrl = logoAiga;
  pdf.addImage(logoUrl, 'PNG', 10, 10, 50, 20);

  // Ajouter le titre du rapport
  pdf.setFontSize(20);
  pdf.setFont('Helvetica', 'bold');
  pdf.text('Rapport de Consommation', pageWidth / 2 + 6, 25, { align: 'center' });

  // Ajouter la date
  pdf.setFontSize(12);
  pdf.setFont('Helvetica', 'normal');
  const dateStr = new Date().toLocaleDateString('fr-FR');
  pdf.text(`Date : ${dateStr}`, pageWidth - 10, 25, { align: 'right' });

  // Ajouter le premier graphique (si disponible) avec meilleure qualité
  if (chartElement1) {
    const canvas1 = await html2canvas(chartElement1, { scale: 3 }); // Améliorer la résolution
    const imgData1 = canvas1.toDataURL('image/png', 1.0); // Qualité maximale
    pdf.addImage(imgData1, 'PNG', 10, yPosition, pageWidth - 20, 100, undefined, 'FAST'); // Compresser légèrement avec 'FAST'

    // Ajouter une bordure autour du premier graphique
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(10, yPosition + 2, pageWidth - 20, 100);
    yPosition += 110; // Mettre à jour la position verticale
  }

  // Ajouter le second graphique (si disponible) avec meilleure qualité
  if (chartElement2) {
    const canvas2 = await html2canvas(chartElement2, { scale: 3 }); // Améliorer la résolution
    const imgData2 = canvas2.toDataURL('image/png', 1.0); // Qualité maximale
    pdf.addImage(imgData2, 'PNG', 10, yPosition, pageWidth - 20, 100, undefined, 'FAST'); // Compresser légèrement avec 'FAST'

    // Ajouter une bordure autour du second graphique
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(10, yPosition + 2, pageWidth - 20, 100);
    yPosition += 110; // Mettre à jour la position verticale
  }

  // Passer à la page suivante s'il n'y a plus de place sur la première page
  if (yPosition + 50 > pdf.internal.pageSize.height) {
    pdf.addPage();
    yPosition = 20;
  }

  // PAGE 2 - Informations et alertes
  pdf.setFontSize(16);
  pdf.setFont('Helvetica', 'bold');
  pdf.text('Informations sur le volume', 10, yPosition);
  yPosition += 20;

  pdf.setFontSize(14);
  pdf.setFont('Helvetica', 'normal');
  pdf.text(`Seuil alerte : ${debitSeuil} L/jour`, 10, yPosition);
  yPosition += 20;

  // Ajouter une ligne de séparation avant les alertes
  pdf.setLineWidth(0.5);
  pdf.line(10, yPosition, pageWidth - 10, yPosition);
  yPosition += 10;

  // Ajouter la section des alertes
  pdf.setFontSize(16);
  pdf.setFont('Helvetica', 'bold');
  pdf.text('Alertes', 10, yPosition);
  yPosition += 15;

  pdf.setFontSize(14);
  pdf.setFont('Helvetica', 'normal');

  if (boitier.dateDepassement && boitier.dateDepassement.length > 0) {
    const lineHeight = 10;

    for (const date of boitier.dateDepassement) {
      if (yPosition + lineHeight > pdf.internal.pageSize.height - 20) {
        pdf.addPage();
        yPosition = 20;
        pdf.setFontSize(16);
        pdf.setFont('Helvetica', 'bold');
        pdf.text('Alertes (suite)', 10, yPosition);
        yPosition += 15;
        pdf.setFontSize(14);
        pdf.setFont('Helvetica', 'normal');
      }

      // Calculer la consommation totale de la journée
      const totalConsumption = await calculateTotalConsumptionForDay(date);

      // Ajouter l'information sur le dépassement et la consommation totale
      pdf.text(`Date: ${new Date(date).toLocaleDateString()} - Volume dépassé: ${totalConsumption} L`, 10, yPosition);
      yPosition += lineHeight;
    }
  } else {
    pdf.text('Aucune alerte enregistrée.', 10, yPosition);
  }

  // Enregistrer le PDF
  pdf.save(`${getChartTitlePDF()}.pdf`);
};


const exportExcel = () => {
  let firstColumnHeader;
  
  switch (granularity) {
    case 'day':
    case 'week':
      firstColumnHeader = 'Heure';
      break;
    case 'month':
      firstColumnHeader = 'Semaine';
      break;
    case 'year':
      firstColumnHeader = 'Mois';
      break;
    default:
      firstColumnHeader = 'Nom';
  }

  // Titre pour la première et deuxième feuille
  const sheetTitle = getChartTitleExcel(); // Titre du graphique ou du fichier

  // Première feuille pour les données de consommation avec un titre et une ligne vide
  const consommationData = [
    [sheetTitle],    // Ligne de titre
    [],              // Ligne vide pour espace
    [firstColumnHeader, 'Consommation (m³)'], // En-têtes de colonnes
    ...data2.map(item => [item.name, item.Consommation])
  ];

  // Deuxième feuille pour les données de débit avec un titre et une ligne vide
  const debitData = [
    [sheetTitle],    // Ligne de titre
    [],              // Ligne vide pour espace
    [firstColumnHeader, 'Débit (m³/s)'], // En-têtes de colonnes
    ...data3.map(item => [item.name, item.Debit])
  ];

  // Créer le classeur Excel
  const workbook = XLSX.utils.book_new();

  // Ajouter la feuille pour la consommation
  const consommationSheet = XLSX.utils.aoa_to_sheet(consommationData);
  XLSX.utils.book_append_sheet(workbook, consommationSheet, 'Consommation');

  // Ajouter la feuille pour le débit
  const debitSheet = XLSX.utils.aoa_to_sheet(debitData);
  XLSX.utils.book_append_sheet(workbook, debitSheet, 'Débit');

  // Exporter le fichier Excel
  XLSX.writeFile(workbook, `${getChartTitleExcel()}.xlsx`);
};

const handleCancelClick = () => {
  setIsEditing(false);
  setNewMotorName(motor.name); // Réinitialiser si annulation
};

const handleSaveClick = async () => {
  try {
    const response = await axios.post(`${API_URL}/boitier/nomboitier`, {
      id_boitier: motor.id,
      nom_boitier: newMotorName
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    console.log('Boîtier modifié:', response.data);

    // Mettre à jour le state et le localStorage
    setMotor(prevMotor => ({ ...prevMotor, name: newMotorName }));
    localStorage.setItem('boitierName', newMotorName);
    setIsEditing(false);
  } catch (error) {
    console.error('Erreur lors de la modification du boîtier:', error);
  }
};

// Au chargement du composant, on vérifie le localStorage
useEffect(() => {
  const storedMotorName = localStorage.getItem('boitierName');
  if (storedMotorName) {
    setMotor(prevMotor => ({ ...prevMotor, name: storedMotorName }));
  }
  setNewMotorName(storedMotorName);
}, []);


return (
  <div className="min-h-screen flex flex-col bg-gray-100 text-gray-900">
    <Header user={user} />
    <div className="flex-grow flex items-center justify-center py-12">
        <div className="max-w-4xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-semibold text-center mb-8">
            {isEditing ? (
              <div className="flex flex-col items-center justify-center space-y-4">
              <input
                type="text"
                value={newMotorName}
                onChange={(e) => setNewMotorName(e.target.value)}
                className="border border-gray-300 p-3 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none w-96"
                placeholder="Nom du moteur"
              />
              <div className="flex space-x-4">
                <button
                  onClick={handleSaveClick}
                  className="bg-gradient-to-r from-green-400 to-green-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                  Valider
                </button>
                <button
                  onClick={handleCancelClick}
                  className="bg-gradient-to-r from-red-400 to-red-600 text-white px-5 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                  Annuler
                </button>
              </div>
            </div>            
            ) : (
              <div className="flex items-center justify-center space-x-4">
                <span>{motor.name}</span>
                <button onClick={() => setIsEditing(true)} className="ml-2">
                  <FaEdit className="text-black-500 hover:text-blue-700" />
                </button>
              </div>
            )}
          </h1>
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
            <h2 className="text-center text-xl lg:text-2xl font-semibold mb-6 text-gray-800">
              Informations du Compteur
            </h2>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Client: <span className="font-bold text-blue-600">{motor.clientName || "Pas encore de client"}</span>
            </p>
            <p className="text-lg font-medium text-gray-700">
              Localisation: <span className="font-bold text-blue-600">{motor.location || "Pas encore de localisation"}</span>
            </p>
            {!motor.clientName || !motor.location ? (
              <AddClientLocation
                motor={motor}
                token={user.token}
              />
            ) : (
              <p>Client et localisation déjà définis.</p>
            )}
          </div>
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
              <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Fréquences des mails</h2>
              <div className="mb-6">
                <SetDefaultEmailFrequency token={user.token} motor={motor} />
              </div>
            </div>
            {user.admin && (
  <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
    <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres du Boîtier </h2>
    {console.log(impulseVolume)}
    {impulseVolume !== "" && (
      <div className="mb-6">
        <label htmlFor="impulseVolume" className="block text-lg font-medium text-gray-700 mb-2">
          Impulsions par Litre :
        </label>
        <input
          id="impulseVolume"
          type="number"
          step="0.000001"
          className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Litres par impulsion"
          value={impulseVolume || ''}
          onChange={handleVolumeChange}
          disabled
        />
      </div>
    )}

    {/* Conteneur pour le bouton "Créer un nouvel étalonnage" */}
      <button
        className="bg-blue-500 text-white max-sm:flex max-sm:justify-center mb-6 mr-4 px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300"
        onClick={() => setCreatingEtalonnage(true)}
      >
        Créer un nouvel étalonnage
      </button>

    {confirmationMessage && (
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-4 rounded relative mb-6" role="alert">
        <span className="block sm:inline">{confirmationMessage}</span>
      </div>
    )}

    {creatingEtalonnage && (
      <>
        <div className="mt-6 mb-6">
          <label htmlFor="etalonnageName" className="block text-lg font-medium text-gray-700 mb-2">
            Nom de l'Étalonnage :
          </label>
          <input
            id="etalonnageName"
            type="text"
            className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Nom de l'étalonnage"
            value={etalonnageName || ''}
            onChange={handleEtalonnageNameChange}
          />
        </div>

        <div className="mb-6">
          <label htmlFor="impulseVolume" className="block text-lg font-medium text-gray-700 mb-2">
            Impulsions par Litre :
          </label>
          <input
            id="impulseVolume"
            type="number"
            step="0.000001"
            className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Litres par impulsion"
            onChange={handleVolumeChange}
          />
        </div>

        {/* Conteneur pour les boutons de validation et d'annulation */}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            className="bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-green-600 transition-all duration-300"
            onClick={handleCreateEtalonnage}
          >
            Valider
          </button>
          <button
            className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-600 transition-all duration-300"
            onClick={() => {
              setCreatingEtalonnage(false);
              setEtalonnageName(''); // Réinitialise le nom de l'étalonnage
              setImpulseVolume(''); // Réinitialise le volume
            }}
          >
            Annuler
          </button>
        </div>
      </>
    )}
    
    {/* Conteneur pour le bouton "Voir l'historique des étalonnages" */}
      <button
        className="bg-gray-500 text-white px-6 py-3 rounded-lg shadow-lg max-sm:flex max-sm:justify-center mt-6 hover:bg-gray-600 transition-all duration-300"
        onClick={fetchEtalonnageHistory}
      >
        Voir l'historique des étalonnages
      </button>
    
    {etalonnageHistory.length > 0 && (
      <div className="mt-8 p-6 border border-gray-300 rounded-lg bg-white shadow-sm">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Historique des Étalonnages :</h3>

        <button
          className="flex items-center justify-center bg-blue-500 text-white font-medium px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          onClick={() => setEtalonnageHistory([])}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          Fermer l'historique
        </button>

        <ul className="space-y-4 mt-4">
          {etalonnageHistory.map((etalonnage) => (
            <li key={etalonnage._id} className="p-4 bg-gray-100 rounded-lg border border-gray-300">
              <div className="flex justify-between items-center">
                <strong className="text-lg text-gray-900">{etalonnage.nom}</strong>
                <span className="text-sm text-gray-600">{moment(etalonnage.dateDebut).format('LL')}</span>
              </div>
              <p className="text-gray-700 mt-1">Valeur : <span className="font-semibold">{etalonnage.valeur}</span></p>
              <p className="text-gray-500 text-sm">Fin : {etalonnage.dateFin ? moment(etalonnage.dateFin).format('LL') : 'En cours'}</p>
              <button
                className="mt-2 text-red-500 hover:underline"
                onClick={() => handleDeleteRequest(etalonnage)}
              >
                Supprimer
              </button>
            </li>
          ))}
        </ul>
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Confirmer la suppression</h3>
              <p>Êtes-vous sûr de vouloir supprimer l'étalonnage <strong>{selectedEtalonnage.nom}</strong> ?</p>
              <div className="mt-6 flex justify-end">
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded"
                  onClick={handleDeleteConfirm}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </div>
)}
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8 mb-8">
            <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Alerte de Dépassement</h2>
            <div className="mb-6">
              <label htmlFor="debitSeuil" className="block text-lg font-medium text-gray-700 mb-2">
                Définir Volume de Dépassement par Jour (L) :
              </label>
              <input
                id="debitSeuil"
                type="number"
                className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Litres par jour"
                value={debitSeuil}
                onChange={(e) => setDebitSeuil(e.target.value)}
              />
            </div>
            <button
              className="bg-blue-500 text-white px-6 py-3 rounded-lg"
              onClick={handleSubmitSeuil}
            >
              Enregistrer
            </button>
            <div className="mt-8 bg-white border border-gray-300 shadow-md rounded-lg p-6">
      <h3 className="text-2xl font-semibold mb-4 text-gray-800 border-b pb-2">Historique des Dépassements</h3>

      {!boitier ? (
        <p className="text-gray-500">Chargement des données...</p>
      ) : (
        <div>
          {boitier.dateDepassement && boitier.dateDepassement.length === 0 ? (
            <p className="text-gray-500">Aucun dépassement n'a été enregistré pour l'instant.</p>
          ) : (
            <div>
              <p className="text-gray-700 mb-4">Nombre total de dépassements : {boitier.dateDepassement.length}</p>
              <ul className="list-disc pl-5 space-y-2">
                {boitier.dateDepassement.map((date, index) => (
                  <li key={index} className="text-gray-700 bg-gray-100 p-2 rounded-md shadow-sm">
                    <p>Dépassement le : {new Date(date).toLocaleDateString()}</p>
                    <p>
                      Consommation totale de la journée :{' '}
                      <span className="font-bold text-blue-600">
                        {consumptions[date] ? `${consumptions[date]} L` : 'Calcul en cours...'}
                      </span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
  )}
</div>

      </div>
        <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-8">
          <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Paramètres de Filtrage</h2>
          <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-6">
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de début:</label>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 text-sm lg:text-base font-medium mb-2">Date de fin:</label>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Granularité:</label>
              <select
                value={granularity}
                onChange={e => setGranularity(e.target.value)}
                className="border border-gray-300 p-3 rounded-lg w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availableGranularities.includes('day') && (
                  <option value="day">Jour (10 min par point)</option>
                )}
                {availableGranularities.includes('week') && (
                  <option value="week">Semaine (1 jour par point)</option>
                )}
                {availableGranularities.includes('month') && (
                  <option value="month">Mois (1 semaine par point)</option>
                )}
                {availableGranularities.includes('year') && (
                  <option value="year">Année (1 mois par point)</option>
                )}
              </select>
            </div>
            <button
              onClick={handleSearchClick}
              className="bg-blue-600 text-white py-3 rounded-lg w-full hover:bg-blue-700 transition duration-300 font-semibold"
            >
              Rechercher
            </button>
          </div>
        </div>
        <div className="mt-10 flex flex-col items-center">
  {data2.length > 0 ? (
    <>
      {/* Graphique de consommation */}
      <div className="w-full max-w-3xl" ref={chartContainerRef}>
        <h3 className="text-xl lg:text-2xl font-medium text-center mb-4">{getChartTitle()}</h3>
        <div className="flex justify-start items-center mb-4">
          <label className="text-gray-600 font-medium mr-3">Unité de Consommation :</label>
          <div className="relative">
            <select
              value={unitConsumption}
              onChange={handleUnitConsumptionChange}
              className="appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-10 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
            >
              <option value="litres">Litres</option>
              <option value="m3">m³</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full h-auto">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data2} margin={{ top: 35, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={isMobile ? false : undefined} />
              <YAxis label={{ value: unitConsumption === 'm3' ? 'm³' : 'Litres', angle: -90, position: 'insideLeft' }} />
              <Tooltip formatter={(value, name) => {if (name === "Consommation") {return [`${value} ${unitConsumption === 'm3' ? 'm³' : 'L'}`, name];}return value;}} />
              <Legend />
              <Bar dataKey="Consommation" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Graphique de débit */}
      <div className="w-full max-w-3xl mt-10" ref={secondChartContainerRef}>
  <h3 className="text-xl lg:text-2xl font-medium text-center mb-4">{getSecondChartTitle()}</h3> 
  <div className="flex justify-start items-center mb-4">
    <label className="text-gray-600 font-medium mr-3">Unité :</label>
    <div className="relative">
      <select
        value={unit}
        onChange={handleUnitChange}
        className="appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-10 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
      >
        <option value="litres">Litre/h</option>
        <option value="m3">m³/h</option>
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  </div>
  <div className="w-full h-auto">
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data3} margin={{ top: 35, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={isMobile ? false : undefined} />
        <YAxis label={{ value: unit === 'm3' ? 'm³/h' : 'Litre/h', angle: -90, position: 'insideLeft' }} />
        <Tooltip formatter={(value, name) => {
          if (name === "Debit") {
            return [`${value} ${unit === 'm3' ? 'm³/h' : 'Litre/h'}`, name];
          }
          return value;
        }} />
        <Legend />
        <Line type="monotone" dataKey="Debit" stroke="#82ca9d" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  </div>
</div>

    </>
  ) : (
    <p className="text-center text-gray-600">Aucune donnée à afficher</p>
  )}
</div>
        <div className="mt-4 flex justify-center space-x-4">
          <button
            onClick={exportPDF}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
          >
            Exporter en PDF
          </button>
          <button
            onClick={exportExcel}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
          >
            Exporter en Excel
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

}
